import { createApp, defineAsyncComponent } from 'vue';
import store from 'shared/store';

function mountQuickLinks() {
	const quickLinksBlock = document.getElementById('quick-link-block');

	if(!quickLinksBlock) {
		console.warn('[quickLinks Container not found');
		return;
	}

	const app = createApp({
		name: 'quick-link block',
	});

	app.component('quick-link-block', defineAsyncComponent(
		() => import('./quickLinkBlock.vue')
	));

	app.use(store);

	app.mount(quickLinksBlock);
}

mountQuickLinks();